import { date } from "@storybook/addon-knobs";

export function userGoogleReviewOptIn(transactionId:string){
    if(typeof window !== 'undefined'){
        const googleReviewed = localStorage.getItem('gcr-' + transactionId);
        if(googleReviewed === null){
            return false;
        }else{
            return true;
        }
    }
}

export function setUserGoogleReviewOptIn(transactionId:string){
    if(typeof window !== 'undefined'){
        const googleReviewed = localStorage.getItem('gcr-' + transactionId);
        if(googleReviewed === null){
            localStorage.setItem(`gcr-${transactionId}`,"true");
        }
    }
}

export function userFacebookPixelSent(transactionId:string){
    if(typeof window !== 'undefined'){
        const pixelSent = localStorage.getItem(`fbp-${transactionId}`);
        if(pixelSent === null){
            return false;
        }else{
            return true;
        }
    }
}

export function setUserFacebookPixelSent(transactionId:string){
    if(typeof window !== 'undefined'){
        const pixelSent = localStorage.getItem(`fbp-${transactionId}`);
        if(pixelSent === null){
            localStorage.setItem(`fbp-${transactionId}`,"true");
        }
    }
}

export function userFloodlightSent(transactionId:string){
    if(typeof window !== 'undefined'){
        const pixelSent = localStorage.getItem(`fls-${transactionId}`);
        if(pixelSent === null){
            return false;
        }else{
            return true;
        }
    }
}

export function setUserFloodlightSent(transactionId:string){
    if(typeof window !== 'undefined'){
        const pixelSent = localStorage.getItem(`fls-${transactionId}`);
        if(pixelSent === null){
            localStorage.setItem(`fls-${transactionId}`,"true");
        }
    }
}